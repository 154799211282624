import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import './index.css'

const Action = ({ to='', text='', icon='', shadow=true, target='', aria='' }) => {
  return (
    <Link to={to} target={target}>
      {icon ?
      <button className={shadow ? 'mobile shadow' : 'mobile'} tabIndex={0} type='button' aria-label={aria} >
        {icon}
      </button>
      : 
      <button className={shadow ? 'action shadow icon' : 'action'} tabIndex={0} type='button' aria-label={aria} >
        {text}
      </button>}
    </Link>
  )
}

Action.propTypes = {
  to: PropTypes.string,
  text: PropTypes.string,
  icon: PropTypes.any,
  shadow: PropTypes.bool,
  target: PropTypes.string,
  aria: PropTypes.string
}

export default Action