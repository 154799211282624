import { lazy } from "react"
import { Helmet } from 'react-helmet-async'
import Video from '../../components/Video'
import Carousel from '../../components/Carousel'
import useCheckDevice from '../../components/hooks/useCheckDevice'
import Solutions from '../../components/Solutions'

const ManagedServices = lazy(() => import('../../components/ManagedServices'))
const Clients = lazy(() => import('../../components/Clients'))
const Partners = lazy(() => import('../../components/Partners'))
const ScrollToTop = lazy(() => import('../../components/buttons/ScrollToTop'))

const Home = (props) => {

  const device = useCheckDevice()
  const isDesktop = device.isDesktop
  const orientation = device.orientation

  const meta = {
    "title": "Best IT Services and Solutions Providers in LA, California",
    "description": "Explore the best IT services and solutions providers in LA, California. Discover experts offering tailored tech solutions for your business needs.",
    "url": "https://www.7lits.com",
    "keywords":"managed it solutions, it solutions company, it services and solutions, it solutions near me, it solution services, it solutions for small business, it solutions & services, it solutions los angeles, managed it solutions los angeles, los angeles it solutions, it services los angeles, it managed services los angeles, it managed service providers los angeles, los angeles it managed services provider, los angeles managed it services, it services near me, managed it services near me, it consulting services near me, affordable cyber security service, managed cyber security services, cyber security services near me, managed network security services, network security as a service, network and security services, secured network services"
  }

  const slides = [
    {
      className:'img-managed-it-services',
      portrait: '/managed-it-services-los-angeles-mobile-min.webp',
      landscape: '/managed-it-services-los-angeles-min.webp',
      description: ['MANAGED IT SERVICES'],
      text:'Centralize application and data access with our private cloud solution.',
      buttonText:'Learn More',
      to:'/managed-it-services',
      alt:'Managed IT Services'
    },
    {
      className:'img-cloud-services-los-angeles',
      portrait: '/cloud-services-los-angeles-mobile-min.webp',
      landscape: '/cloud-services-los-angeles-min.webp',
      description: ['CLOUD7 SOLUTIONS'],
      text:'Centralize application and data access with our private cloud solution.',
      buttonText:'Learn More',
      to:'/cloud-services',
      alt:'Cloud Services'
    },
    {
      className:'img-cybersecurity fade-in-image',
      portrait: '/cybersecurity-los-angeles-mobile-min.webp',
      landscape: '/cybersecurity-los-angeles-min.webp',
      description: ['CYBERSECURITY'],
      text:'Centralize application and data access with our private cloud solution.',
      buttonText:'Learn More',
      to:'/network-and-cybersecurity',
      alt:'Cybersecurity'
    },
    {
      className:'img-disaster-recovery fade-in-image',
      portrait: '/business-data-recovery-los-angeles-mobile-min.webp',
      landscape: '/business-data-recovery-los-angeles-min.webp',
      description: ['BUSINESS CONTINUITY &','DISASTER RECOVERY'],
      text:'Centralize application and data access with our private cloud solution.',
      buttonText:'Learn More',
      to:'/business-continuity-and-disaster-recovery',
      alt:'Business Continuity and Disaster Recovery'
    },
  ]

  const jsonData = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "logo": "https://www.7lits.com/logo-7l-full.webp",
    "name": "7 Layer IT Solutions",
    "legalName": "7 Layer IT Solutions, Inc.",
    "url": "https://www.7lits.com",
    "description":"IT Services",
    "keywords":["IT Services","Cybersecurity","Network Services"],
    "image":[
      "https://www.7lits.com/cloud-services-los-angeles.jpg"
    ],
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "8921 Fullbright Avenue",
      "addressLocality": "Chatsworth",
      "addressRegion": "Los Angeles",
      "postalCode": "91311",
      "addressCountry": "US"
    },
    "contactPoint" : [
        { "@type" : "ContactPoint",
          "telephone" : "+18187930070",
          "email": "support@7lits.com",
          "contactType" : "Customer Service",
          "areaServed" : "US"
        }
    ],
    "geo": {
      "@type": "GeoCoordinates",
      "latitude": 34.232845506176105,
      "longitude": -118.57792319076856
    },
    "telephone": "+18187930070",
    "openingHoursSpecification": [
      {
        "@type": "OpeningHoursSpecification",
        "dayOfWeek": [
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday"
        ],
        "opens": "07:00",
        "closes": "19:00"
      }
    ],
    "sameAs": [
      "https://www.linkedin.com/company/7lits",
      "https://www.facebook.com/7lits",
      "https://www.instagram.com/7lits",
      "https://www.yelp.com/biz/7-layer-it-solutions-chatsworth",
      "https://maps.app.goo.gl/nBaianyM2RhBLg3g8"
    ],
    "foundingDate": "2007",
    "founders": [
      {
        "@type": "Person",
        "name": "Angel Cifuentes"
      },
      {
        "@type": "Person",
        "name": "Michael Sagoian"
      }
    ]
  }

  return (
    <>
      <Helmet>
        <title>{meta.title}</title>
        <link rel="canonical" href={meta.url} />
        <link rel="preload" href="/managed-it-services-los-angeles-min.webp" as="image" type="image/webp" />
        <link rel="preload" href="/cloud-services-los-angeles-mobile-min.webp" as="image" type="image/webp" />
        <meta name="description" content={meta.description} />
        <meta name="keywords" content={meta.keywords} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={meta.title} />
        <meta property="og:description" content={meta.description} />
        <meta name="twitter:creator" content="7 Layer IT Solutions" />
        <meta name="twitter:card" content="website" />
        <meta name="twitter:title" content={meta.title} />
        <meta name="twitter:description" content={meta.description} />
        <script type="application/ld+json">{JSON.stringify(jsonData)}</script>
      </Helmet>
      <div style={{
        display:"flex",
        flexDirection:"column",
        alignItems:"center",
        justifyContent:"center",
        overflow:"hidden"
      }}>
        {isDesktop && <Video />}
        {!isDesktop && <Carousel slides={slides} orientation={orientation} />}
        <ManagedServices />
        <Clients />
        <Partners />

        <Solutions />
        <ScrollToTop {...props} />
      </div>
    </>
  )
}

export default Home