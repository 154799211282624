import { useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

function RemoveTrailingSlash() {
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (location.pathname.endsWith('/') && location.pathname.length > 1) {
      navigate(location.pathname.slice(0, -1), { replace: true })
    }
  }, [location, navigate])

  return null
}

export default RemoveTrailingSlash