
import { Box, Fab, Fade, useMediaQuery, useScrollTrigger, useTheme } from '@mui/material'
import PropTypes from 'prop-types'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'

function ScrollTop(props) {
  const { children } = props
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  })

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      '#back-to-top-anchor',
    )

    if (anchor) {
      anchor.scrollIntoView({
        block: 'center',
      })
    }
  };

  return (
    <Fade in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: 'fixed', bottom: 14, right: 16 }}
      >
        {children}
      </Box>
    </Fade>
  )
}

const ScrollToTop = (props) => {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('laptop'))
  return (
    <>
      {isDesktop && <ScrollTop {...props}>
        <Fab size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>}
    </>
  )
}

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired
}

export default ScrollToTop