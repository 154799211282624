import Action from '../buttons/Action'
import PropTypes from 'prop-types'
import './index.css'

const Solutions = ({ page='Home', buttonText='Request A Quote'}) => {
  return (
    <div className={page=='Home' ? 'solutions-container-home' : 'solutions-container' }>
      <h2>
        Let&apos;s find the right IT solutions for your business.
      </h2>
      <h3>
        Speak with a dedicated expert today about securing your infrastructure and modernizing your IT services.
      </h3>
      <div className='quote-btn-container'>
        <Action
          to='/contact'
          text={buttonText}
          aria={buttonText}
          shadow={false}
        />
      </div>

    </div>
  )
}

Solutions.propTypes = {
  page: PropTypes.string,
  buttonText: PropTypes.string
}

export default Solutions