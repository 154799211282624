import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'
import App from './components/App/index.jsx'
import ScrollToTop from './components/Scroll/ScrollToTop.jsx'
import ScrollToAnchor from './components/Scroll/ScrollToTop.jsx'
import './index.css'
import RemoveTrailingSlash from './components/RemoveTrailingSlash/index.jsx'

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <HelmetProvider>
      <BrowserRouter>
        <RemoveTrailingSlash />
        <ScrollToTop />
        <ScrollToAnchor />
        <App />
      </BrowserRouter>
    </HelmetProvider>
  </React.StrictMode>
)
