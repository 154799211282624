import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';

const LoadingSpinner = () => {
  return (
    <>
      <div style={{position:"fixed", height:"100%", width:"100%", top:0, left:0, margin:0, padding:0, backgroundColor:"grey", opacity:"0.5", zIndex:1000000}}></div>
      <div style={{position:"fixed", top:"45%", left:"46.3%", zIndex:1000002}}>
        <Stack sx={{ color: 'grey.500', opacity:1}} spacing={2} direction="row">
          <CircularProgress size="7rem" color="success" />
        </Stack>
      </div>
    </>
  );
}

export default LoadingSpinner