import PropTypes from 'prop-types'
import './index.css'

const NoLinkAction = ({ text='', shadow=true, aria='' }) => {
  return (
    <button className={shadow ? 'action shadow icon' : 'action'} tabIndex={0} type='button' aria-label={aria} >
        {text}
    </button>
  )
}

NoLinkAction.propTypes = {
  to: PropTypes.string,
  text: PropTypes.string,
  shadow: PropTypes.bool,
  target: PropTypes.string,
  aria: PropTypes.string
}

export default NoLinkAction