import { useEffect } from 'react'
import video from '/Videos/managed-it-services-los-angeles.mp4'
import VideoOverlay from '../VideoOverlay'
import './index.css'

const Video = () => {

  useEffect(() => {
    const vid = document.getElementById("vid");
    vid.addEventListener("timeupdate", function () {
        if (video.currentTime >= 30.0) {
            video.currentTime = 0.0
        }
    })
  })

  return (
    <div className='video-container'>      
      <video id='vid' src={video} autoPlay muted loop playsInline/>
      <VideoOverlay />
    </div>
  )
}

export default Video