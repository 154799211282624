import { useEffect, useState } from 'react';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import PropTypes from 'prop-types'
import Action from '../buttons/Action'
import './index.css'

const Carousel = ({ slides, orientation }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      if(currentIndex >= slides.length) {
        setCurrentIndex(0)
      }else{
        nextSlide()
      }
    },8000)
    return () => clearTimeout(timer)
  })

  const prevSlide = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const nextSlide = () => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  return (
    <div className='carousel-container'>
      <div className='carousel-img'>
        <img className={slides[currentIndex].className} src={slides[currentIndex][orientation]} alt={slides[currentIndex].alt}/>
      </div>
      <div className='chevy left'>
        <BsChevronLeft onClick={prevSlide} color="#fcfcfc" size={34} />
      </div>
      <div className='chevy right'>
        <BsChevronRight onClick={nextSlide} color="#fcfcfc" size={34} />
      </div>
      <div className='title-container'>
        <div className='title-text-container'>
          {slides[currentIndex].description.map((text, textIndex) => {
            return <div className='title-text' key={textIndex}>{text}</div>
          })}
        </div>
        <div className='title-btn'>
          <Action to={slides[currentIndex].to} text='Learn More' aria='Learn More' />
        </div>
      </div>
    </div>
  )
}

Carousel.propTypes = {
  slides: PropTypes.array.isRequired,
  orientation: PropTypes.string.isRequired
}

export default Carousel