import { useCallback, useRef, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { menuItems } from '../../static/menuItems'
import Action from '../buttons/Action'
import './index.css'

function getMenuLastIndex() {
  let indexes = {}
  menuItems.forEach(function(menu) {
    indexes[menu.title] = menu.children.length - 1
  })
  return indexes
}

export default function Menu() {
  const lastIndex = getMenuLastIndex()
  const [menus, setMenus] = useState({})

  const handleOpen = useCallback(e => {
    let updatedMenus = {}
    menuItems.forEach(function(menu) {
      if (menu.title === e.target.innerText) {
        updatedMenus[menu.title] = true
      } else {
        updatedMenus[menu.title] = false
      }
    })
    setMenus(updatedMenus)
  },[])
  
  const handleClose = useCallback(() => {
    let updatedMenus = {}
    menuItems.forEach(function(menu) {
      updatedMenus[menu.title] = false
    })
    setMenus(updatedMenus)
  },[])

  const dropMenu = useRef()
  window.addEventListener('click', (e) => {
    if (e.target !== dropMenu.current) { handleClose }
  })

  return (
    <header id='header' className='menu-container'>
      <div className='menu'>
        <div>
          <Link to="/" onFocus={handleClose} aria-label='go-to-home'>
            <img className='logo' src="/logo-7l-full.webp" alt='7 Layer IT Solutions Logo'/>
          </Link>
        </div>
        <nav>
          <ul className='nav-links'>
            {menuItems.map(({ title, path, aria, children }, index) => {
              return (
                <li key={index} >
                  <div className='feature-menu' onMouseEnter={e => handleOpen(e)} onMouseLeave={handleClose}>
                    <NavLink aria-label={aria} to={path} className={menus[title] ? 'active-link nav-link' : 'nav-link'} onClick={handleClose} onFocus={handleOpen}>
                      <p>{title}</p>
                    </NavLink>
                    {menus[title] && <ul ref={dropMenu} role='menu' id={aria} className='drop-menu' style={{backgroundColor:"#ededed"}} >
                      {children.map(({ subtitle, path, icon, aria }, idx) => (
                        <li key={idx} className='nav-item'>
                          <Link to={path} aria-label={aria} onClick={handleClose}>
                            <div className={idx === 0 ? "nav-item-div-first" : idx === lastIndex[title] ? "nav-item-div-last" : "nav-item-div"}>
                              <div>{icon}</div>
                              <p>{subtitle}</p>
                            </div>
                          </Link>
                        </li>
                      ))}
                    </ul>}
                </div>
              </li>
              );
            })}
          </ul>
        </nav>
        <div className='box' onFocus={handleClose}>
          <Action
            to='/contact'
            text='Contact Us'
            aria="Contact Us"
          />
        </div>
      </div>
    </header>
  )
}
