import PropTypes from 'prop-types'
import ScrollToTop from '../buttons/ScrollToTop'
import Seo from "../../components/Seo"
import './index.css'

const Service = ({ data }, props) => {
  return (
    <>
      <Seo 
        title={data.title}
        description={data.description}
        type="website"
        name="7 Layer IT Solutions"
        url={"https://www.7lits.com/" + data.url}
        jsonData={data.jsonData}
      />
      <div className='service-container'>
        <div className='image-container'>
          <img alt={data.alt} src={"/services" + data.image} />
        </div>
        <div className='service'>
          <h1>{data.name}</h1>
            {data.text.map((line, index) => {
              return (
                <p key={index}>{line}</p>
              )
            })}
        </div>
        <ScrollToTop {...props} />
      </div>
    </>
  )
}

Service.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    text: PropTypes.array.isRequired,
    jsonData: PropTypes.object
  })
}

export default Service