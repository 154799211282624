import { useCallback, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { menuItems } from '../../static/menuItems'
import Action from '../buttons/Action'
import './index.css'
/* import MenuRoundedIcon from '@mui/icons-material/MenuRounded'
import CloseIcon from '@mui/icons-material/Close' */
import { FaPhoneVolume } from "react-icons/fa6";
import { PiPhone } from "react-icons/pi"

const MobileMenu = () => {
  const [open, setMenu] = useState(false)
  const [burger_class, setBurgerClass] = useState("burger-bar unclicked")

  const closeMenu = () => {
    if (open) {
      document.body.classList.remove('no-scroll')
    }
    setBurgerClass("burger-bar unclicked")
    setMenu(false)
  }
  const toggleMenu = useCallback(() => {
    if (!open) {
      document.body.classList.add('no-scroll')
      setBurgerClass("burger-bar clicked")
    } else {
      document.body.classList.remove('no-scroll')
      setBurgerClass("burger-bar unclicked")
    }
    
    setMenu(!open)
  },[open])

  return (
    <header id='header' className='mobile-header'>
      <div className='mobile-menu'>
        <div className='logo-container'>
          <Link to="/" role="link" aria-label='Home' onClick={closeMenu}>
            <img src="/logo-7l-full.webp" alt='7 Layer IT Solutions Logo'/>
          </Link>
        </div>
        <div className='btn-container'>
          <div className='action-container'>
            <Action
              to="tel:+18187930070"
              target='_blank'
              text='Contact'
              icon={<PiPhone />}
              aria="Call Support"
            />
          </div>
          <div className="hamburger-menu" onClick={toggleMenu}>
            <div className={burger_class} ></div>
            <div className={burger_class} ></div>
            <div className={burger_class} ></div>
          </div>
                {/* <div className='menu-btn-container'>
            <MenuRoundedIcon onClick={toggleMenu} aria-label="Open Menu"/>
            </div> */}
          {/* open && <div className='menu-btn-container'>
            <CloseIcon onClick={toggleMenu} aria-label="Close Menu"/>
          </div> */}
        </div>
      </div>
      <div className='spacer' />
      <div className={open ? 'mobile-menu-container open' : 'mobile-menu-container'}>
        <div className='nav-container'>
          <nav>
            <ul className='mobile-nav-headers'>
              {menuItems.map(({ title, path, aria, children }, index) => {
                return (
                  <li key={index}>
                    <NavLink aria-label={aria} to={path} className={({ isActive }) => (isActive ? 'active-link nav-link' : 'nav-link')} onClick={toggleMenu}>
                      <span className='mobile-nav-title'>{title}</span>
                    </NavLink>
                    <ul className='mobile-nav-links'>
                      {children.map(({ subtitle, path, icon, aria }, index) => {
                        return (
                          <li key={index}>
                            <Link to={path} aria-label={aria} onClick={toggleMenu}>
                              <div className='mobile-menu-item'>
                                <div className='mobile-menu-item-icon'>{icon}</div>
                                <span className='mobile-nav-subtitle'>{subtitle}</span>
                              </div>
                            </Link>
                          </li>
                        )
                      })}
                      {title === 'Client Resources'
                        ? <li>
                            <Link to='contact' aria-label='contact-us' onClick={toggleMenu}>
                              <div className='mobile-menu-item'>
                                <div className='mobile-menu-item-icon'><FaPhoneVolume className='volume'/></div>
                                <span className='mobile-nav-subtitle'>Contact Us</span>
                              </div>
                            </Link>
                          </li>
                        : ''}
                    </ul>
                  </li>
                )
              })}
            </ul>
          </nav>
        </div>
      </div>
    </header>
  )
}

export default MobileMenu